import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { PointsOptionBreakUpComponent } from '../../Components/points-option-break-up/points-option-break-up.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { UtilityService } from 'src/app/services/utility.service';
import { AllocatePointsService } from 'src/app/services/allocate-points.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  MaxLengthValidator,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '../../Directives/custom-validators';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { PointsBreakUpComponent } from '../../Components/points-break-up/points-break-up.component';
import { PointsDetailBreakupComponent } from '../popup/points-detail-breakup/points-detail-breakup.component';
import { UsedInCatalogComponent } from '../popup/used-in-catalog/used-in-catalog.component';
import { PostTripPointsComponent } from '../popup/post-trip-points/post-trip-points.component';
import { PurchasedCatalogPointsComponent } from '../popup/purchased-catalog-points/purchased-catalog-points.component';
import { PostTripMiscPointsComponent } from '../popup/post-trip-misc-points/post-trip-misc-points.component';
@Component({
  selector: 'app-tripenrolment-customer-points-status',
  templateUrl: './tripenrolment-customer-points-status.component.html',
  styleUrls: ['./tripenrolment-customer-points-status.component.css'],
})
export class TripenrolmentCustomerPointsStatusComponent implements OnInit {
  response: any = {};
  responseTrip: any = {};
  responsePoint: any = {};
  otherCP: any = {};
  isDisableOther: boolean = false;
  model: any = {};
  //lastYear = new Date().getFullYear() - 1;
  lastYear: number;
  currentYear = new Date().getFullYear();
  previousYear = new Date().getFullYear() - 1;
  catalogPointsList: any = [];
  customerCatalogPointList: any = [];
  yearList: any = [];
  cYearList: any = [];
  submitted: boolean = false;
  cbpSubmitted: boolean = false;
  CPsubmitted: boolean = false;
  confirmRes: boolean = true;
  catalogPointsCode;
  totalAvailablePoints: string = '';
  deletePointRolledover = false;
  @Input() xEnrollmentId: number;
  @Input() xYear: number;

  //Added on 24/05/2023
  purchasedCustomerCatalogPointList: any = [];
  reAllocatedMessage: any = {};
  public displayedColumns = [
    'category',
    'destination',
    'trips',
    'points',
    'pointsOption',
    'total',
    'balance',
  ];
  public displayedRollOverColumns = [
    'rollOverDate',
    'availablePoints',
    'rollOverPoints',
    'pullOverPoints',
    'totalRollOverPoints',
  ];

  public dataSource = new MatTableDataSource<PointsEntity>();
  public dataSourceRollOver = new MatTableDataSource<RollOverEntity>();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  enrollmentId: number;
  customerId: number;
  sub: any;
  tab: number = 1;
  tripBalancePoints = 0.0;
  RollOverPoints = '';
  futureYearPostTripPoints = 0;
  displayFutureYearPostTripPoints = false;
  catalogPoints = 0.0;
  isOther: boolean = false;
  public tripForm: FormGroup;
  public catalogForm: FormGroup;
  public catalogBuyPoint: FormGroup;
  public displayReAllocatePointButton = false;
  tripWhsDefaultList: any = [];
  currentUserId = this._utilityService.getCurrentUserId();

  //OtherCatalogs:any=[];
  otherCatalogs: any[] = [];
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _allocatePointsService: AllocatePointsService,
    private _utilityService: UtilityService,
    private _router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.lastYear = this.xYear;

    this.sub = this.route.params.subscribe((params) => {
      this.enrollmentId = +params['id'];
    });
    // It will use when enrollment id is null or 0
    if (!this.enrollmentId) {
      this.enrollmentId = this.xEnrollmentId;
    }

    this.yearList = this._utilityService.fillYear(
      new Date().getFullYear() - 1,
      new Date().getFullYear()
    );
    this.cYearList = this._utilityService.fillYear(
      new Date().getFullYear(),
      new Date().getFullYear()
    );
    this.getAllocationDetail(true);
    this.generateForm();
    this.generateCatalogBuyPointForm();
    this.getOtherCatalog();
  }
  get f() {
    return this.tripForm.controls;
  }

  get fCBP() {
    return this.catalogBuyPoint.controls;
  }
  get fc() {
    return this.catalogForm.controls;
  }
  getAllocationDetail(bindDropdown = false) {
    // display last year points
    // debugger;

    this._allocatePointsService
      .getCustomerWisePointsAllocation(this.enrollmentId, this.lastYear)
      .subscribe((result) => {
        this.model = result;
        this.CPsubmitted = false;

        this.customerId = this.model.customerId;
        this.catalogPoints = this.model.catalogPoints;
        this.catalogPointsList = this.model.catalogPointList;
        this.deletePointRolledover = this.model.deletePointRolledover;
        this.customerCatalogPointList =
          this.model.customerCatalogPointList.filter((c) => !c.isOther);

        this.tripBalancePoints =
          parseFloat(this.model.lastYearBalance) -
          parseFloat(this.model.totalTripPoints);
        this.dataSource.data = this.model.allocatedPointList as PointsEntity[];
        this.dataSourceRollOver.data = this.model
          .rollOverPointsList as RollOverEntity[];
        this.futureYearPostTripPoints = 0;
        // this.totalAvailablePoints = (
        //   this.model.lastYearBalance - +this.model.postTripPoints
        // ).toFixed(2);

        this.totalAvailablePoints = parseFloat(
          this.model.lastYearBalance
        ).toFixed(2);

        if (this.model.postTrips && this.model.postTrips.length > 0) {
          let fPoints = this.model.postTrips
            .filter((t) => t.years == this.currentYear)
            .map((a) => a.points);
          if (fPoints && fPoints.length > 0) {
            this.displayFutureYearPostTripPoints = true;
            this.futureYearPostTripPoints = fPoints.reduce(function (a, b) {
              return (parseFloat(a) + parseFloat(b)).toFixed(2);
            });
          }
          this.generateForm();

          // Bind Post Trip Point for the current Year only
          let currentYearPostTripPoints = this.model.postTrips.filter(
            (t) => t.years == this.currentYear
          );

          if (currentYearPostTripPoints.length > 0) {
            currentYearPostTripPoints.forEach((trip) => {
              this.addMoreTrip(trip);
            });
          } else {
            this.addMoreTrip(null);
          }
        } else {
          this.generateForm();
          this.addMoreTrip(null);
        }

        // Catalog Buy Points
        if (
          this.model.catalogBuyPoints &&
          this.model.catalogBuyPoints.length > 0
        ) {
          // let fPoints = this.model.postTrips
          //   .filter((t) => t.years == this.currentYear)
          //   .map((a) => a.points);
          // if (fPoints && fPoints.length > 0) {
          //   this.futureYearPostTripPoints = fPoints.reduce(function (a, b) {
          //     return (parseFloat(a) + parseFloat(b)).toFixed(2);
          //   });
          // }
          this.generateCatalogBuyPointForm();
          this.model.catalogBuyPoints.forEach((trip) => {
            this.addMoreBuyPoint(trip);
          });
        } else {
          this.generateCatalogBuyPointForm();
          this.addMoreBuyPoint(null);
        }

        let otherCP = this.model.customerCatalogPointList.filter(
          (c) => c.isOther
        );
        if (otherCP && otherCP.length > 0) {
          this.isOther = true;
          this.isDisableOther = true;
          this.otherCP = otherCP;
          this.generateCPForm();
          this.otherCP.forEach((point) => {
            this.addMoreCatalog(point);
          });
        } else {
          this.generateCPForm();
          this.addMoreCatalog(null);
        }

        this.ReAllocatePointButtonDisplay();
        if (bindDropdown) {
          this.GetTripWhsDefaultDropdowns(
            this.currentYear,
            this.model.ppewhsCode
          );
        }
      });
  }

  updateCustomerWiseAllocationStatus(status: number) {
    //this.pointMessage=''
    this.responseTrip = {};
    this.responsePoint = {};
    this.reAllocatedMessage = {};
    //let totalBalancePoints=+this.model.balancePoints+ +this.model.catalogPoints;
    //  if(status==3)
    //  {
    //    if(!this.catalogPoints && +this.catalogPoints<=0 )
    //    {
    //     this.responsePoint = {
    //       messageCode:2,
    //       isShow:true,
    //       message:'Please enter catalog points.'
    //     };
    //     return;
    //    }

    //   else if(+this.catalogPoints>totalBalancePoints ){
    //     this.responsePoint = {
    //       messageCode:2,
    //       isShow:true,
    //       message:'Catalog points can not be more than balance points.'
    //     };
    //   return;
    // }

    //  }
    var entity = {
      years: this.lastYear,
      customerId: this.customerId,
      status: status,
      catalogPoints: +this.catalogPoints,
      certificatePoints: +this.model.totalPointsReleased,
    };

    // console.log(entity);

    if (status == 1) {
      // Re-Allocate the points on AR Release Yes Click
      this._allocatePointsService
        .reAllocateCustomerPoints(this.lastYear, this.model.customerId)
        .subscribe((result) => {
          if (result.messageCode == 1) {
            // Update the AR Release status to released
            this._allocatePointsService
              .updateCustomerWiseAllocationStatus(entity)
              .subscribe((result) => {
                this.responsePoint = result;
                if (
                  this.response.messageCode === 1 ||
                  this.responsePoint.messageCode === 1
                ) {
                  this.getAllocationDetail();
                }
              });
          }
        });
    } else {
      this._allocatePointsService
        .updateCustomerWiseAllocationStatus(entity)
        .subscribe((result) => {
          if (status != 3) {
            this.response = result;
          } else {
            this.responsePoint = result;
          }
          if (
            this.response.messageCode === 1 ||
            this.responsePoint.messageCode === 1
          ) {
            this.getAllocationDetail();
          }
        });
    }
  }

  saveRollOverPoints() {
    this.responseTrip = {};
    this.reAllocatedMessage = {};
    if (!this.RollOverPoints && +this.RollOverPoints <= 0) {
      this.responseTrip.messageCode = 2;
      this.responseTrip.isShow = true;
      this.responseTrip.message = 'Please enter rollover points.';
      return;
    } else if (+this.RollOverPoints > +this.model.balancePoints) {
      this.responseTrip.messageCode = 2;
      this.responseTrip.isShow = true;
      this.responseTrip.message =
        'RollOver points can not be more than available points ' +
        this.model.balancePoints;
      return;
    }
    let saveData = {
      years: +this.lastYear,
      customerId: +this.customerId,
      rollOverPoints: this.RollOverPoints,
      availablePoints: this.model.balancePoints,
      updatedBy: this._utilityService.getCurrentUserId(),
    };
    this._allocatePointsService
      .saveRollOverPoints(saveData)
      .subscribe((result) => {
        this.responseTrip = result;
        if (this.responseTrip.messageCode == 1) {
          this.getAllocationDetail();
          this.RollOverPoints = '';
        }
      });
  }

  exportCertificate() {
    let fileName = 'Certificates_' + this.model.customerNumber;
    const exportData = [
      {
        'Company Number': this.model.customerNumber,
        'Company Name': this.model.companyName,
        Year: this.lastYear,
        'Issue Date': this.model.issueDate,
        'Certificate Points': this.model.totalPointsReleased,
      },
    ];

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  allocationTab(tab) {
    this.response = {};
    this.responseTrip = {};
    this.responsePoint = {};
    this.reAllocatedMessage = {};
    this.tab = tab;
  }
  generateForm() {
    this.tripForm = this.fb.group({
      tripPoints: this.fb.array([]),
    });
  }
  generateCPForm() {
    this.catalogForm = this.fb.group({
      catalogPoints: this.fb.array([]),
    });
  }

  generateCatalogBuyPointForm() {
    this.catalogBuyPoint = this.fb.group({
      buyPoints: this.fb.array([]),
    });
  }

  get a_tripPoints() {
    return this.f.tripPoints as FormArray;
  }

  get a_CatalogBuyPoints() {
    return this.fCBP.buyPoints as FormArray;
  }

  addMoreTrip(trip) {
    this.submitted = false;
    if (trip) this.a_tripPoints.push(this.editTrip(trip));
    else this.a_tripPoints.push(this.createTrip());
  }

  removeTrip(i: number) {
    if (confirm('Are you sure to delete the current record?')) {
      this.a_tripPoints.removeAt(i);
    }
  }

  createTrip() {
    return this.fb.group({
      TripWhsDefaultId: [
        '',
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Id: [0],
      Descriptions: ['', [Validators.required, CustomValidators.checkEmpty]],
      Points: ['', [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: ['', [Validators.required, CustomValidators.checkEmpty]],
      //Years: [this.model.balancePoints >= 0 ? this.lastYear : this.currentYear],
      Years: [this.currentYear],
    });
  }

  editTrip(trip) {
    return this.fb.group({
      TripWhsDefaultId: [
        trip.tripWhsDefaultId,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Id: [trip.id],
      Descriptions: [
        trip.descriptions,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Points: [trip.points, [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: [
        trip.pointsValue,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Years: [trip.years],
    });
  }

  get a_catalogPoints() {
    return this.fc.catalogPoints as FormArray;
  }
  addMoreCatalog(point) {
    this.submitted = false;
    if (point) this.a_catalogPoints.push(this.editCatalog(point));
    else this.a_catalogPoints.push(this.createCatalog());
  }

  removeCatalog(i: number) {
    if (
      confirm('Are you sure you want to delete the selected catalog points?')
    ) {
      this.a_catalogPoints.removeAt(i);
    }
    if (this.a_catalogPoints.length == 0) this.isDisableOther = false;
  }

  createCatalog() {
    return this.fb.group({
      id: [0],
      customerId: [this.customerId],
      code: ['', [Validators.required, CustomValidators.checkEmpty]],
      item: ['', [Validators.required, CustomValidators.checkEmpty, Validators.maxLength(250)]],
      ppeCost: ['', [Validators.required, CustomValidators.checkEmpty]],
      points: ['', [Validators.required, CustomValidators.checkEmpty]],
      years: [this.lastYear.toString()],
      isOther: [true],
    });
    console.log(this.catalogForm.controls);

  }

  editCatalog(point) {
    return this.fb.group({
      id: [point.id],
      customerId: [point.customerId],
      code: [point.code, [Validators.required, CustomValidators.checkEmpty]],
      item: [point.item, [Validators.required, CustomValidators.checkEmpty]],
      ppeCost: [
        point.ppeCost,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      points: [
        point.points,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      years: [point.years.toString()],
      isOther: [point.isOther],
    });
    console.log(this.catalogForm.controls);
  }

  //#region  "Catalog Buy Points"
  addMoreBuyPoint(trip) {
    this.cbpSubmitted = false;
    if (trip) {
      this.a_CatalogBuyPoints.push(this.editBuyPoint(trip));
    } else {
      this.a_CatalogBuyPoints.push(this.createBuyPoint());
    }
  }

  removeBuyPoint(i: number) {
    if (
      confirm('Are you sure you want to delete the purchased catalog points?')
    ) {
      // GET ID
      //       console.log(this.a_CatalogBuyPoints);
      let id = (<FormGroup>this.a_CatalogBuyPoints.controls[i]).controls.Id
        .value; //(FormControl(<FormArray> this.a_CatalogBuyPoints.controls[i])).controls)
      if (id == 0) {
        this.a_CatalogBuyPoints.removeAt(i);
        return;
      }

      // Check Ballance Point is less than Catalog Point
      let balancePoints = parseFloat(this.model.balancePoints);
      let totalCatalogBuyPoints = parseFloat(this.model.totalCatalogBuyPoints);

      if (balancePoints < totalCatalogBuyPoints) {
        this.responsePoint = {
          messageCode: 2,
          isShow: true,
          message:
            "These catalog points are already used. It can't be deleted.",
        };
        return;
      }

      this._allocatePointsService
        .deleteCatalogBuyPoints(id)
        .subscribe((result) => {
          this.responsePoint = result;
          if (this.responsePoint.messageCode === 1) {
            this.getAllocationDetail();
          } else {
            this.responsePoint = {
              messageCode: 2,
              isShow: true,
              message: 'Some thing went wrong, please try again.',
            };
          }
        });
    }
  }

  createBuyPoint() {
    return this.fb.group({
      Id: [0],
      Descriptions: ['', [Validators.required, CustomValidators.checkEmpty]],
      Points: ['', [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: ['', [Validators.required, CustomValidators.checkEmpty]],
      Years: [this.model.balancePoints >= 0 ? this.lastYear : this.currentYear],
    });
  }

  editBuyPoint(trip) {
    return this.fb.group({
      Id: [trip.id],
      Descriptions: [
        trip.descriptions,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Points: [trip.points, [Validators.required, CustomValidators.checkEmpty]],
      PointsValue: [
        trip.pointsValue,
        [Validators.required, CustomValidators.checkEmpty],
      ],
      Years: [trip.years],
    });
  }

  saveCatalogBuyPoint() {
    this.responsePoint = {};
    this.cbpSubmitted = true;
    if (this.a_CatalogBuyPoints.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let dataSave = this.catalogBuyPoint.value;
    let totalTripPoints = 0;
    // if (
    //   dataSave.tripPoints &&
    //   dataSave.tripPoints.length > 0 &&
    //   dataSave.tripPoints.filter((t) => t.Years == this.lastYear).length > 0
    // ) {
    //   totalTripPoints = +dataSave.tripPoints
    //     .filter((t) => t.Years == this.lastYear)
    //     .map((a) => a.Points)
    //     .reduce(function (a, b) {
    //       return (parseFloat(a) + parseFloat(b)).toFixed(2);
    //     });
    // }
    // let availableBalance =
    //   parseFloat(this.model.balancePoints) +
    //   parseFloat(this.model.postTripPoints);
    // let totalBalance = availableBalance + parseFloat(this.model.rollOverPoints);

    // if (totalTripPoints > 0 && totalTripPoints > totalBalance) {
    //   this.responsePoint = {
    //     messageCode: 2,
    //     isShow: true,
    //     message:
    //       "Total post trip points for year " +
    //       this.lastYear +
    //       " can not be greater than, the sum of balance and rolled over points.",
    //   };
    //   return;
    // } else if (
    //   totalTripPoints > availableBalance &&
    //   totalTripPoints <= totalBalance
    // ) {
    //   const confirmDialog = this.matDialog.open(ConfirmationDialogComponent, {
    //     data: {
    //       title: "Alert",
    //       message:
    //         "Not enough points to cover this. Do you want to rollback points from rollover points or assign it to next year?",
    //     },
    //   });

    //   confirmDialog.afterClosed().subscribe((res) => {
    //     if (res === true) {
    //       dataSave.CustomerId = this.customerId;
    //       this._allocatePointsService
    //         .savePostTripPoints(dataSave)
    //         .subscribe((result) => {
    //           this.responsePoint = result;
    //           if (this.responsePoint.messageCode === 1) {
    //             this.getAllocationDetail();
    //           } else {
    //             this.responsePoint = {
    //               messageCode: 2,
    //               isShow: true,
    //               message: "Some thing went wrong, please try again.",
    //             };
    //           }
    //         });
    //     }
    //   });
    // } else {
    //   dataSave.CustomerId = this.customerId;
    //   this._allocatePointsService
    //     .saveCatalogBuyPoints(dataSave)
    //     .subscribe((result) => {
    //       this.responsePoint = result;
    //       if (this.responsePoint.messageCode === 1) {
    //         this.getAllocationDetail();
    //       } else {
    //         this.responsePoint = {
    //           messageCode: 2,
    //           isShow: true,
    //           message: "Some thing went wrong, please try again.",
    //         };
    //       }
    //     });
    // }

    dataSave.CustomerId = this.customerId;
    this._allocatePointsService
      .saveCatalogBuyPoints(dataSave)
      .subscribe((result) => {
        this.responsePoint = result;
        if (this.responsePoint.messageCode === 1) {
          this.getAllocationDetail();
        } else {
          this.responsePoint = {
            messageCode: 2,
            isShow: true,
            message: 'Some thing went wrong, please try again.',
          };
        }
      });
  }

  //#endregion

  savePostTripPoint() {
    this.responsePoint = {};
    this.submitted = true;
    if (this.a_tripPoints.invalid) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }
    let dataSave = this.tripForm.value;

    //console.log(dataSave);

    let totalTripPoints = 0;
    if (
      dataSave.tripPoints &&
      dataSave.tripPoints.length > 0 &&
      dataSave.tripPoints.filter((t) => t.Years == this.lastYear).length > 0
    ) {
      totalTripPoints = +dataSave.tripPoints
        .filter((t) => t.Years == this.lastYear)
        .map((a) => a.Points)
        .reduce(function (a, b) {
          return (parseFloat(a) + parseFloat(b)).toFixed(2);
        });
    }
    let availableBalance =
      parseFloat(this.model.balancePoints) +
      parseFloat(this.model.postTripPoints);
    let totalBalance = availableBalance + parseFloat(this.model.rollOverPoints);
    if (totalTripPoints > 0 && totalTripPoints > totalBalance) {
      this.responsePoint = {
        messageCode: 2,
        isShow: true,
        message:
          'Total post trip points for year ' +
          this.lastYear +
          ' can not be greater than, the sum of balance and rolled over points.',
      };
      return;
    } else if (
      totalTripPoints > availableBalance &&
      totalTripPoints <= totalBalance
    ) {
      const confirmDialog = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Alert',
          message:
            'Not enough points to cover this. Do you want to rollback points from rollover points or assign it to next year?',
        },
      });

      confirmDialog.afterClosed().subscribe((res) => {
        if (res === true) {
          dataSave.CustomerId = this.customerId;
          dataSave.Years = this.cYearList[0];
          dataSave.UpdatedBy = this.currentUserId;
          this._allocatePointsService
            .savePostTripPoints(dataSave)
            .subscribe((result) => {
              this.responsePoint = result;
              if (this.responsePoint.messageCode === 1) {
                this.getAllocationDetail();
              } else {
                this.responsePoint = {
                  messageCode: 2,
                  isShow: true,
                  message: 'Some thing went wrong, please try again.',
                };
              }
            });
        }
      });
    } else {
      dataSave.CustomerId = this.customerId;
      dataSave.Years = this.cYearList[0];
      dataSave.UpdatedBy = this.currentUserId;
      this._allocatePointsService
        .savePostTripPoints(dataSave)
        .subscribe((result) => {
          this.responsePoint = result;
          if (this.responsePoint.messageCode === 1) {
            this.getAllocationDetail();
          } else {
            this.responsePoint = {
              messageCode: 2,
              isShow: true,
              message: 'Some thing went wrong, please try again.',
            };
          }
        });
    }
  }
  changeCatalogPoint(e) {
    if (e.target.checked) this.otherCP.isOther = true;
    else this.otherCP.isOther = false;
  }
  addMoreCatalogPoint() {
    if (this.catalogPointsCode > 0) {
      let item = this.catalogPointsList.filter(
        (c) => c.id == this.catalogPointsCode
      )[0];
      this.customerCatalogPointList.push(item);
      this.catalogPointsCode = '';
    }
  }

  removeCatalogPoint(i: number) {
    if (
      confirm('Are you sure you want to delete the selected catalog points? ')
    ) {
      this.customerCatalogPointList.splice(i, 1);
    }
  }
  saveCatalogPoints() {
    this.CPsubmitted = this.isOther;
    if (this.a_catalogPoints.invalid && this.CPsubmitted) {
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      if (invalidFields[1]) {
        if (invalidFields[2]) invalidFields[2].focus();
        invalidFields[1].focus();
        return;
      }
    }

    let catPointsdata = this.catalogForm.value;

    const isValidCatalogPoint = (point) => {
      return point.points !== "0" && point.points !== "00" && point.points !== "000" && point.points !== "0000"
      && point.points !== "00000" && point.points !== "000000" && point.points !== "0000000";
    };
    //Filter out invalid catalog points
    console.log('isValidCatalogPoint',isValidCatalogPoint);
    let invalidCatalogPoints = catPointsdata.catalogPoints.filter(point => !isValidCatalogPoint(point) &&point.points !== "");

    console.log('validCatalogPoints',invalidCatalogPoints);
    if (invalidCatalogPoints.length > 0) {
      this.responsePoint = {
        messageCode: 2,
        isShow: true,
        message: 'Catalog points can not save 00.0',
      };
      return;
    }
   console.log('catPointsdata',catPointsdata.catalogPoints);

    let allData = [];
    allData = [
      ...this.customerCatalogPointList,
      ...catPointsdata.catalogPoints
    ];

    catPointsdata.catalogPoints = allData;
    catPointsdata.customerId = +this.customerId;
    catPointsdata.years = +this.lastYear;
    catPointsdata.updatedBy = this.currentUserId;

    let totalcatalogPoints = 0;
    if (
      allData &&
      allData.length > 0 &&
      allData.filter((d) => +d.points > 0).length > 0
    ) {
      totalcatalogPoints = +allData
        .filter((d) => +d.points > 0)
        .map((a) => a.points)
        .reduce(function (a, b) {
          return (parseFloat(a) + parseFloat(b)).toFixed(2);
        });
    } else this.isOther = false;

    let availableBalance =
      parseFloat(this.model.balancePoints) +
      parseFloat(this.model.catalogPoints);
    let totalBalance = availableBalance + parseFloat(this.model.rollOverPoints);

    // Add Condition to Pick Point From Roll Overed Points

    if (totalcatalogPoints > totalBalance) {
      this.responsePoint = {
        messageCode: 2,
        isShow: true,
        message: 'Not enough points are available to cover this purchase.',
      };
      return;
    } else if (
      totalcatalogPoints > availableBalance &&
      totalcatalogPoints <= totalBalance
    ) {
      const confirmDialog = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Alert',
          message:
            'Rolled over points will be adjusted to cover this transaction.',
        },
      });

      confirmDialog.afterClosed().subscribe((res) => {
        if (res === true) {
          catPointsdata.CustomerId = this.customerId;
          this._allocatePointsService
            .saveCatalogPoints(catPointsdata)
            .subscribe((result) => {
              this.responsePoint = result;
              if (this.responsePoint.messageCode === 1) {
                this.getAllocationDetail();
              } else {
                this.responsePoint = {
                  messageCode: 2,
                  isShow: true,
                  message: 'Some thing went wrong, please try again.',
                };
              }
            });
        }
      });
    } else {
      this._allocatePointsService
        .saveCatalogPoints(catPointsdata)
        .subscribe((result) => {
          this.responsePoint = result;
          if (this.responsePoint.messageCode === 1) {
            this.getAllocationDetail();
          } else {
            this.responsePoint = {
              messageCode: 2,
              isShow: true,
              message: 'Some thing went wrong, please try again.',
            };
          }
        });
    }
  }

  deleteRollOverPoint(id) {
    if (confirm('Are you sure to delete the rolled over points?')) {
      var currentUserId = this._utilityService.getCurrentUserId();

      this._allocatePointsService
        .deleteRollOverPoint(id, currentUserId)
        .subscribe((result) => {
          this.responsePoint = result;
          if (this.responsePoint.messageCode === 1) {
            this.getAllocationDetail();
          } else {
            this.responsePoint = {
              messageCode: 2,
              isShow: true,
              message: 'Some thing went wrong, please try again.',
            };
          }
        });
    }
  }
  getPointsOptionBreakUp(tripId: number, destination: string) {
    this._allocatePointsService
      .getPointsOptionBreakUp(tripId, this.model.customerId)
      .subscribe((result) => {
        // console.log(result);
        let data = { destination: destination, pointList: result };
        //this.model = result;
        const dialogConfig = new MatDialogConfig();
        // The user can't close the dialog by clicking outside its body
        dialogConfig.disableClose = true;
        // dialogConfig.id = "modal-component";
        dialogConfig.width = '650px';
        dialogConfig.data = data;
        this.matDialog.open(PointsOptionBreakUpComponent, dialogConfig);
      });
  }
  getPointsBreakUp() {
    let data = {
      lastYearRollOverPoints: this.model.lastYearRollOverPoints,
      lastYearEarnPoints: this.model.lastYearEarnPoints,
      postTripPoints: this.model.postTripPoints,
      lastYearBalance: this.model.lastYearBalance,
      lastYearChildPoints: this.model.lastYearChildPoints,
      isCustomerChildCompany: this.model.isCustomerChildCompany,
      parentCustomerNumber: this.model.parentCustomerNumber,
    };
    //this.model = result;
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = '350px';
    dialogConfig.data = data;
    this.matDialog.open(PointsBreakUpComponent, dialogConfig);
  }

  pointsdetailbreakup_click() {
    //this.model = result;
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = {};
    this.matDialog.open(PointsDetailBreakupComponent, dialogConfig);
  }

  usedincatalog_click() {
    //this.model = result;

    let data = {
      years: this.lastYear,
      customerId: this.model.customerId,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = data;
    this.matDialog.open(UsedInCatalogComponent, dialogConfig);
  }

  posttrippoints_click(year) {
    //this.model = result;
    let data = {
      years: year,
      customerId: this.model.customerId,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = data;
    this.matDialog.open(PostTripPointsComponent, dialogConfig);
  }

  catalogBuyPointDetail() {
    let data = {
      years: this.lastYear,
      customerId: this.model.customerId,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = data;
    this.matDialog.open(PurchasedCatalogPointsComponent, dialogConfig);
  }

  ReAllocatePoints() {
    const confirmDialog = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Alert',
        message:
          'System will reset the current points allocation. Are you sure you want to Re-Allocate points?',
      },
    });

    confirmDialog.afterClosed().subscribe((res) => {
      if (res === true) {
        this._allocatePointsService
          .reAllocateCustomerPoints(this.lastYear, this.model.customerId)
          .subscribe((result) => {
            this.reAllocatedMessage = result;
            if (this.reAllocatedMessage.messageCode == 1) {
              this.getAllocationDetail();
            }
          });
      }
    });
  }

  ReAllocatePointButtonDisplay() {
    // console.log("Calling ReAllocatePointButtonDisplay");
    // console.log(this.model.customerId);
    this._allocatePointsService
      .getReAllocateEnabled(this.model.customerId)
      .subscribe((result) => {
        // console.log(result);
        this.displayReAllocatePointButton = result.data;
        if (this.lastYear != new Date().getFullYear() - 1) {
          this.displayReAllocatePointButton = false;
        }
      });
  }

  GetTripWhsDefaultDropdowns(years: number, ppeWhsCode: string) {
    this._allocatePointsService
      .getTripWhsDefaultDropdowns(years, ppeWhsCode)
      .subscribe((result) => {
        this.tripWhsDefaultList = result;
      });
  }
  bindWHSCodeValue(event: any, index: number) {
    // console.log(event.target.value);

    var selectedId = event.target.value;
    if (selectedId == '') {
      return;
    }

    if (selectedId > 0) {
      var selectedInfo = this.tripWhsDefaultList.filter(
        (x) => x.id == selectedId
      );

      // Bind Data into Post Trip New Row
      this.a_tripPoints.controls[index].patchValue({
        TripWhsDefaultId: selectedInfo[0].id,
        Points: selectedInfo[0].pointsPerTrip.toString(),
        PointsValue: selectedInfo[0].pointsValue,
      });
    } else {
      this.a_tripPoints.controls[index].patchValue({
        TripWhsDefaultId: 0,
        Points: null,
        PointsValue: null,
      });
    }
  }
  SavePostTripAndMiscPoint() {
    let data = {
      years: this.lastYear,
      customerId: this.model.customerId,
      ppeWhsCode: this.model.ppewhsCode,
    };

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    // dialogConfig.id = "modal-component";
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '500px';
    dialogConfig.data = data;
    const modalDialog = this.matDialog.open(
      PostTripMiscPointsComponent,
      dialogConfig
    );

    modalDialog.afterClosed().subscribe({
      next: (data) => {
        if (data) {
          this.reAllocatedMessage = data;
          // Update the AR Release status to released
          //Put on hold AR Release for Last Year on 03 jan 2025
          // this._allocatePointsService
          //   .reAllocateCustomerPoints(this.lastYear, this.model.customerId)
          //   .subscribe((result: any) => {
          //     if (result.messageCode === 1) {
          //       this.getAllocationDetail();
          //     }
          //   });
        }
      },
    });
  }
  numberOnlyWithDecimal(event): boolean {
    var dotCount = 0;
    var checkNumberOnly = '';
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      dotCount += 1;
      checkNumberOnly = event.target.value;
      var numericCheck = event.target.value.toString();
      if (numericCheck.includes('.')) {
        dotCount += 1;
      }
      if (dotCount > 1) {
        dotCount = 0;
        return false;
      }
    }
    if (charCode > 31 && (charCode <= 45 || charCode > 57 || charCode == 47)) {
      return false;
    }
    checkNumberOnly = event.target.value;
    var checkString = '';
    if (checkNumberOnly != null) {
      var numeric = event.target.value.toString();
      if (numeric.includes('.')) {
        var checkNumeric = numeric.split('.');
        if (checkNumeric.length > 2) {
          return false;
        }
        // checkString = checkNumeric[1].split("");
        // if (checkString.length > 1) {
        //   return false;
        // }
      }
    }
    dotCount = 0;
    return true;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  numberDecimalAndNegativeValue(event): boolean {
    var dotCount = 0;
    var checkNumberOnly = '';
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      dotCount += 1;
      checkNumberOnly = event.target.value;
      var numericCheck = event.target.value.toString();
      if (numericCheck.includes('.')) {
        dotCount += 1;
      }
      if (dotCount > 1) {
        dotCount = 0;
        return false;
      }
    }
    if (charCode > 31 && (charCode < 45 || charCode > 57 || charCode == 47)) {
      return false;
    }
    checkNumberOnly = event.target.value;
    var checkString = '';
    if (checkNumberOnly != null) {
      var numeric = event.target.value.toString();
      if (numeric.includes('.')) {
        var checkNumeric = numeric.split('.');
        if (checkNumeric.length > 2) {
          return false;
        }
        // checkString = checkNumeric[1].split("");
        // if (checkString.length > 1) {
        //   return false;
        // }
      }
    }
    dotCount = 0;
    return true;
  }
  getOtherCatalog() {
    this._allocatePointsService.getotherCatalog().subscribe((result) => {
      this.otherCatalogs = result;
    });

  }
}
export interface PointsEntity {
  category: string;
  destination: string;
  trips: string;
  points: string;
  pointsOption: string;
  total: string;
  balance: string;
  cssClass: string;
  pointType: number;
}

export interface RollOverEntity {
  rollOverDate: string;
  rollOverPoints: string;
  pullOverPoints: string;
  availablePoints: string;
  totalRollOverPoints: string;
}
